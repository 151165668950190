export function buildURL (path, queryOpt = {}) {
	const prefix = 'http://prefix'
	const builder = new URL(path, prefix)

	Object.keys(queryOpt).forEach(key => {
		const val = queryOpt[key]

		if (val) {
			builder.searchParams.set(key, val)
		}
	})

	return builder.toString().replace(prefix, '')
}
