import React from 'react'

export default function Help () {
	return <>
		<div>
			Evey Long and her team are available to answer any questions right now<br />
			Email: <a
				style={{ color: 'var(--accent-color)' }}
				href="mailto:evey@lovieawards.com">evey@lovieawards.com</a><br />
			Call: +1 212 675 3555<br />
			Text: +1 646 863 4133<br />
		</div>

		<br />
	</>
}
