import React from 'react'
import VideoList from './VideoList'
import Help from './Help'
import styles from './ScreenWelcome.module.scss'

const ScreenWelcome = ({ user = null, section, currentSection, setPiece }) => {
	if (!user) {
		return <div />
	}

	return (
		<div className="section-extra-wide">
			<div className="section">
				<div className={styles.greeting}>Hi, {user[0].Organization}</div>

				<p className={styles.intro}>
					For the 12th Annual Lovies we’re celebrating all 7 Words of Lovie speeches in our online gallery.
					Choose the work below to upload your speech.
				</p>

				<div>
					{user.map((project, p) =>
						<div key={p} className={styles.project}>
							<div className={styles.upload} onClick={() => setPiece(project)}>
								<div className={styles.pieceTitle}>{project.PieceTitle}</div>
								{project.awards.map((award, a) =>
									<div key={a} className={styles.pieceAward}>
										<div className={styles.pieceAwardTitle}>
											{award.pv
												? `${award.AwardLevel} / People's Lovie`
												: award.AwardLevel}
										</div>

										{award.MediaType} / {award.CategoryType} / {award.Category}
									</div>
								)}
							</div>

							<VideoList key={project.PiceID} pieceID={project.PieceID} />
						</div>
					)}
				</div>

				<Help />
			</div>
		</div>
	)
}
export default ScreenWelcome
