import React, { useRef } from 'react'
// import Slider from "react-slick";
import BrowseCell from './BrowseCell'
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel'
import arrowLeft from '../img/arrow-left.svg'
import arrowRight from '../img/arrow-right.svg'

export default function Rivers ({ playlists }) {
	const slideShow = useRef()

	if (!playlists) return <div />

	return (
		playlists.map((list, l) =>
			<div key={l} className="playlist-river">
				<div className="playlist-title">{list.name} Winners</div>

				{list.videos.length > 0
					? (
						<CarouselProvider
							step={window.innerWidth > 768 ? 4 : 2}
							interval={5000}
							infinite
							isPlaying={list.videos.length > 4}
							visibleSlides={window.innerWidth > 768 ? 4 : 2}
							totalSlides={list.videos.length}
							naturalSlideWidth={100}
							naturalSlideHeight={100}
							isIntrinsicHeight
						>
							<Slider ref={slideShow}>
								{list.videos.map((video, i) => {
									const ii = list.videos.length - 1

									const prev = i === 0
										? list.videos[ii]
										: list.videos[i]

									const next = i === ii
										? list.videos[0]
										: list.videos[i]

									return <Slide key={`cell${i}`} className="cell-huh">
										<BrowseCell
											data={video}
											prev={prev}
											next={next}
											playlist={list.name}
										/>
									</Slide>
								})}
							</Slider>
							{/* <ButtonBack>Back</ButtonBack>
											<ButtonNext>Next</ButtonNext> */}
						</CarouselProvider>
					)
					: <div style={{ paddingTop: 10, textAlign: 'center' }}>No Videos in playlist yet...</div> }
			</div>
		)
	)
}
