import React from 'react'
// import loader from '../img/heart.svg';
import styles from './Loader.module.scss'
import { ReactComponent as Logo } from './../img/lovies-2022/logo.svg'

export default function Loader ({ text = 'Uploading your speech<br />Give it a minute' }) {
	return (
		<div className={styles.root}>
			<div className={styles.inner}>
				{/* <img src={loader} alt="loading" /> */}
				<Logo />

				<span className={styles.text} dangerouslySetInnerHTML={{ __html: text }} />
			</div>
		</div>
	)
}
