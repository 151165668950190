import React from 'react'
import styles from './PartnerList.module.scss'

const logoContext = require.context('./img/lovies-2022/partner-logos', true, /\.png$/)

const links = {
	'10087 - Hundo - Branding - Logo White.png': 'https://www.hundo.xyz/',
	'dazed-logo-white.png':                      'https://www.dazeddigital.com/',
	'moca-logo-white.png':                       'https://museumofcryptoart.com/',
	'NFT_banner_light_400x400.png':              'https://nftevening.com/',
	'pinterest-wordmark-white.png':              'https://business.pinterest.com/en-gb/',
	'PNFTS.png':                                 'https://palm.io/',
	'radiodayseu-logo-white.png':                'https://www.radiodayseurope.com/',
	'tcv3.png':                                  'https://globaldeficonference.com/',
}

const Logos = ({ logos }) => {
	return <div>
		{logos.map(logo =>
			<a
				key={logo}
				href={links[logo]}
				target="_blank"
				rel="noreferrer"
			>
				<img
					key={logo}
					className={styles.logo}
					src={logoContext(`./${logo}`)}
				/>
			</a>
		)}
	</div>
}

const list = [
	'10087 - Hundo - Branding - Logo White.png',
	'dazed-logo-white.png',
	'moca-logo-white.png',
	'NFT_banner_light_400x400.png',
	'pinterest-wordmark-white.png',
	'PNFTS.png',
	'radiodayseu-logo-white.png',
	'tcv3.png',
]

const PartnerList = () => {
	return <div className={styles.root}>
		<div className={styles.heading}>Partners</div>
		<br />
		<Logos logos={list} />
	</div>
}

export default PartnerList
