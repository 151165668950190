import React, { useEffect } from 'react'
import {
	BrowserRouter as Router,
	Routes,
	Route,
	useLocation, Navigate
} from 'react-router-dom'
import Browse from './browse/Browse'
import App from './App'
import Home from './home/Home'
import Speech from './browse/Speech'
import Vote from './Vote'
import GodMode from './GodMode'

import HomePlaceholder from './home/HomePlaceholder'

function ScrollToTop () {
	const { pathname } = useLocation()

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [pathname])

	return null
}

export default function R () {
	return (
		<Router>
			<ScrollToTop />
			<Routes>
				<Route path="/emily-god-mode" element={<GodMode />} />
				<Route path="/browse" element={<Browse />} />
				<Route path="/search/:term" element={<Browse />} />
				<Route path="/search" element={<Browse />} />
				<Route path="/upload/:EmailID" element={<App official={true} />} />
				<Route path="/watch/edwardenniful" element={<Navigate to="/watch/edwardenninful" />} />
				<Route path="/watch/:id" element={<Speech />} />
				<Route path="/" element={<Home />} />
				{/* <Route path="/" element={<HomePlaceholder />} /> */}
				<Route path="*" element={<HomePlaceholder />} />
			</Routes>
		</Router>
	)
}
