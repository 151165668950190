import React, { useState, useRef, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
// import Config from '../Config'
import Analytics from '../Analytics'
import Help from './Help'
import Loader from './Loader'
import styles from './ScreenHome.module.scss'
import c from 'classnames'
import Button from './Button'

const LOGIN_URL = 'https://us-central1-webbyreport-811f9.cloudfunctions.net/lovies2022_login'

export default function ScreenHome ({ section, currentSection, login }) {
	const [error, setError] = useState(null)
	const [loading, setLoading] = useState(false)
	const { EmailID } = useParams()
	const id = useRef()

	const validate = async () => {
		if (id.current.value) {
			setLoading(true)
			const data = await axios.post(LOGIN_URL, {
				entrantID: id.current.value,
				emailID:   EmailID
			})

			setLoading(false)

			if (data.data.user) {
				Analytics.sendLogin(data.data.user.EntrantID)
				login(data.data.user)
			} else {
				setError('Invalid Entrant ID')
				Analytics.sendEvent('Invalid Login')
			}
			// login(id.current.value);
		} else {
			setError('You need to enter your entrant ID')
		}
	}

	const kp = (e) => {
		setError(null)
		if (e.key === 'Enter') {
			validate()
		}
	}

	useEffect(() => Analytics.sendEvent('Recorder Login Page'), [])

	return (
		<>
			<div className="section-home flex flex-force">
				<div className="section-wide">
					<h1>
						{/* Congratulations!<br /> */}
						<span style={{ fontWeight: 'normal' }}>WELCOME TO THE 12TH ANNUAL LOVIE AWARDS<br />
						</span>
					</h1>

					{/* <p style={{ fontSize: 24 }}> */}
					<p>
						Have Your 7 Words of Lovie Speech? Enter Your Entrant ID to Upload.
					</p>
					<br />

					<div className="form">
						<input ref={id} type="text" placeholder="ENTER ID HERE" onKeyPress={kp} style={{ color: 'var(--accent-color)' }} />

						<br />
						<br />

						<Button
							onClick={validate}
							wide
						>
							I&apos;M READY!
						</Button>

						<div className="error">
							{error || <span dangerouslySetInnerHTML={{ __html: '&nbsp;' }}></span>}
						</div>

						<Help />
					</div>
				</div>
			</div>

			{loading && <Loader text="Logging in..." />}
		</>
	)
}
