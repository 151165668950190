import React from 'react'
import Footer from '../Footer'
// import Header from '../Header'
import styles from './HomePlaceholder.module.scss'
// import LogoWhite from '../img/lovies-2022/logo-white.svg'
import Lottie from 'lottie-react'
import lottieTest from '../img/lovies-2022/Hero-Image.json'

export default function HomePlaceholder () {
	return (
		<div className={styles.root}>
			<div className={styles.content}>
				<div className={styles.animationHolder}>
					<Lottie animationData={lottieTest} />
				</div>

				<div className={styles.bottomText}>
					Mark Your Diaries: The 12th Annual Lovie Awards kicks-off right here on Wednesday, 2nd November at
					14:00 GMT.
				</div>
			</div>

			<Footer/>
		</div>
	)
}
