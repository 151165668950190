import React, { useState, useRef, useMemo } from 'react'
import { Link } from 'react-router-dom'
import Button from '../recorder/Button'
import styles from './HomeHeader.module.scss'
import Floater from './Floater'
import c from 'classnames'
import Lottie from 'lottie-react'
import lottieTest from '../img/lovies-2022/Hero-Image.json'

const names = `
	Michelle Kennedy
	Alice Oseman
	Edward Enninful
	Khaby Lame
	Jaap Haartsen
	DEPT®
	Dr. Sue Black
	Yara Shahidi
	Raphael Gomes
	Lucy Worsley
	Polar
`
	.split('\n')
	.map(s => s.trim())
	.filter(s => s)

const HomeHeader = ({ go }) => {
	return <div className={styles.root}>
		<div className={styles.biglogo}>
			<Lottie animationData={lottieTest} />
		</div>

		<div className={styles.vert}>
			<div>
				<div className={styles.topText}>
					Welcome to the 12th Annual Lovie Awards &bull; Honoring the best of the European Internet
				</div>

				<div className={styles.host}>
					HOSTED BY COMEDIAN AND PODCASTER SOFIE HAGEN
				</div>

				<div className={styles.tickerTitle}>
					With 7 Words of Lovie Speeches and Appearances by:
				</div>

				<div className={styles.ticker}>
					{[1, 2].map(i =>
						<div key={i} className={c(styles.tickerNames, styles[`ticker${i}`])}>
							{names.map(name =>
								<span key={name}>&bull;&nbsp;&nbsp;&nbsp;&nbsp;{name}&nbsp;&nbsp;&nbsp;&nbsp;</span>
							)}
						</div>
					)}
				</div>

				<div className={styles.buttons}>
					<Button onClick={go} outline wide>Featured moments</Button>
					<Button to="/browse" outline wide>
						ALL SPEECHES
					</Button>
				</div>
			</div>
		</div>
	</div>
}

export default HomeHeader
