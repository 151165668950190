import React, { useState } from 'react'
import Identify from '../utils/Identify'
// import VideoCapture from './VideoCapture/VideoCapture';
import Progress from './Progress'
import Analytics from '../Analytics'
import Help from './Help'
import styles from './ScreenRecorder.module.scss'
import Button from './Button'

export default function ScreenRecorder ({ goHome, piece = null, section, currentSection, setShow, official }) {
	const identifyInstance = new Identify()
	const ios = identifyInstance.mobileOS() === 'iOS' || identifyInstance.mobileOS() === 'Android'
	const safari = identifyInstance.safari()
	const [enable, setEnable] = useState(null)

	const [progress, setProgress] = useState(1)

	const finalize = (videoID) => {
		setShow(videoID)
	}

	return <>
		<div
			onClick={goHome}
			className={styles.backButton}
		>Back to Winning Project</div>

		<div className="section-recorder flex">
			<div className="section-wide recorder">
				<div>
					<div className="flex">
						<div className="p-top">
							<p>
								<strong>UPLOAD SPEECH FOR</strong>
							</p>
							<h1>{piece ? piece.PieceTitle : null}</h1>
							<p>
								<strong>Here&apos;s what&apos;s going to happen:</strong>
							</p>
							<p>
								Upload your speech here and it will be posted to our gallery for the world to see.
								Don&apos;t forget to be creative with your 7 Words of Lovie!
							</p>

							<div>
								<br />
								<br />
								<Button wide onClick={() => setShow()}>UPLOAD A VIDEO</Button>
							</div>
							<br />
							<br />
							<Help />
						</div>
					</div>
				</div>
			</div>
			<Progress progress={progress} />
		</div>
	</>
}
