import React from 'react'
import styles from './Button.module.scss'
import c from 'classnames'
import {
	useHref,
	useLinkClickHandler,
} from 'react-router-dom'

const Button = ({
	children, nopad = false, italic = false, wide = false, outline = false,
	// react router props
	to, replace = false, state, target,
	...props
}) => {
	if (to) {
		const href = useHref(to)
		const handleClick = useLinkClickHandler(to, {
			replace,
			state,
			target,
		})

		props.href = href
		props.onClick = (event) => {
			if (!event.defaultPrevented) {
				handleClick(event)
			}
		}
	}

	return React.createElement(
		props.href ? 'a' : 'div',
		{
			...props,
			className: c(styles.root, {
				[styles.nopad]:   nopad,
				[styles.italic]:  italic,
				[styles.wide]:    wide,
				[styles.outline]: outline
			})
		},
		children
	)
}

export default Button
