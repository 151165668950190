import React from 'react'
import Config from '../Config'
import { Link } from 'react-router-dom'
import { buildURL } from '../utils/buildURL'

export default function BrowseCell ({ data, next, prev, playlist }) {
	const linkURL = buildURL(`/watch/${data.id}`, {
		playlist
	})

	return (
		<Link to={linkURL} className="browse-content-cell">
			<div className="browse-content-cell-inner">
				<div className="play-img">
					<img src={`${Config.bucketURL + data.hash}.jpg`} alt="Placeholder" />
					<div className="play-btn"></div>
				</div>

				<div className="browse-river-meta">
					{data.Presenter && <div className="presenter">{data.Presenter} presents to</div>}
					{data.Organization && <div className="company-name">{data.Organization}</div>}
					{/* data.PieceTitle ? <div className="work-name">for {data.PieceTitle}</div>: null */}
				</div>
			</div>
		</Link>
	)
}
