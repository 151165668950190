import React, { useState, useRef, useEffect } from 'react'
import { Player, ControlBar } from 'video-react'
import { Link } from 'react-router-dom'
import Analytics from '../Analytics'
import Config from '../Config'

import { ReactComponent as PlayImg } from '../img/playImg.svg'
import { ReactComponent as PauseImg } from '../img/pauseImg.svg'
import { ReactComponent as RestartImg } from '../img/restartImg.svg'
import linkImg from '../img/link.svg'
import { findByText } from '@testing-library/react'
import SectionBar from './SectionBar'

const SEEK_INTRO = 5.6
const SEEK_OUTRO = 0.5

export default function HomeVideoPlayer ({ data = {}, link = true, currentSection = -1, skipIntro = true, onEnd = null, fiveWords = false, preload = 'auto', setCurrentSection }) {
	const video = useRef()
	const trigger = useRef()
	const pauseTrigger = useRef()
	const [playing, setPlaying] = useState(false)
	const [paused, setPaused] = useState(false)
	const [finished, setFinished] = useState(false)
	const [playbar, setPlaybar] = useState(0)
	const hasPlayed = useRef(false)
	const hasSetup = useRef(false)

	const stopAllVideos = () => {
		/* document.querySelectorAll('.video-trigger-pause').forEach(vid => {
            vid.click();
        }); */

		document.querySelectorAll('video').forEach(vid => {
			// vid.pause();
		})
	}

	const handleChange = (state, prevState) => {
		const d = Math.floor((100 / state.duration) * state.currentTime)
		setPlaybar(d)

		if (skipIntro && state.currentTime >= state.duration - SEEK_OUTRO) {
			console.log('SEEK!!!')
			video.current.pause()
			setPlaying(false)
			setPlaybar(0)
			// setFinished(true);
			onEnd(fiveWords)
		}

		if (state.ended === true && prevState.ended === false) {
			setPlaying(false)
			// setFinished(true);
			if (onEnd) {
				video.current.pause()

				setPlaybar(0)
				onEnd(fiveWords)
			}
		}

		if (state.hasStarted && prevState.paused && skipIntro && !hasPlayed.current) {
			hasPlayed.current = true
			video.current.seek(SEEK_INTRO)
			console.log('SEEK it')
		}
	}

	const play = async () => {
		stopAllVideos()
		setCurrentSection(trigger.current.dataset.id)
		// video.current.video.src = src;

		setPaused(false)
		setPlaying(true)

		await Analytics.videoView(data.videoID, `home - ${data.subtitle} - ${data.title}`)
	}

	const pause = () => {
		setPaused(true)
		setPlaying(false)
		video.current.pause()
	}

	const restart = () => {
		setPlaying(true)
		setFinished(false)
		video.current.play()
	}

	const fixPad = () => {
		document.querySelectorAll('.home-square-video .video-react').forEach(vid => {
			vid.style.paddingTop = '100%'
		})
	}

	useEffect(() => {
		if (video.current) {
			if (!hasSetup.current) {
				hasSetup.current = true
				video.current.subscribeToStateChange(handleChange)
				fixPad()
			}

			if (playing) {
				console.log(`play ${data.hash}`)
				video.current.play()
			}
		}
	}, [playing])

	useEffect(() => {
		if (parseInt(currentSection) === parseInt(trigger.current.dataset.id)) {
			console.log(`playing: ${data.hash}`)
		} else {
			if (video.current) {
				pause()
			}
		}
	}, [currentSection])

	return (
		<div className="video-stack">
			<div ref={trigger} className="video-trigger" onClick={play}></div>
			{playing || finished || paused
				? (
					<Player ref={video} /* poster={Config.bucketURL + data.hash + '.jpg'} */ autoPlay playsInline preload={'auto'} width={'100%'} height={'100%'}>
						<source src={`${Config.bucketURL + data.hash}-share.mp4`} />
						<ControlBar disableCompletely={false} autoHide={false} />
					</Player>
				)
				: <img src={`${Config.bucketURL + data.hash}.jpg`} alt={`${data.title} ${data.title}`} className="video-poster" />}

			{/* playing || paused ? <div className="playbar">
                <div className="playbar-inner" style={{width: playbar + '%'}}></div>
            </div> : null */ }

			{!playing || paused || finished
				? (
					<>
						<div className="home-video-meta">
							{data.prefix &&
								<><div className="home-video-meta-type" role={data.prefix}>
									{data.prefix}
								</div><br /></>}
							<div className="home-video-meta-sub">{data.subtitle}<br /><strong>{data.title}</strong></div>
						</div>
					</>
				)
				: null}
			<div className={playing ? 'video-controls video-controls-playing' : 'video-controls'}>
				{!playing && !finished &&
					<button onClick={play}>
						<div className="video-button-inner"><PlayImg /></div>
					</button>}

				{playing &&
					<button onClick={pause}>
						<div className="video-button-inner"><PauseImg /></div>
					</button>}

				{finished &&
					<button onClick={restart}>
						<div className="video-button-inner"><RestartImg /></div>
					</button>}
			</div>

			{!playing || paused || finished
				? (
					<div className="home-video-linkout">
						<Link to={`/watch/${data.videoID}`}><img src={linkImg} alt="Link to video" /></Link>
					</div>
				)
				: null}
		</div>
	)
}
