const flow = [
	// opening 16x9
	{
		title:    'Sofie Hagen',
		subtitle: 'A Lovie Welcome From Your Host',

		hash:      'lovies-2022-opening',
		videoID:   'monologue',
		presenter: null,
		winner:    null,
		prefix:    '',

		needsWatch: true
	},

	// 6 UP
	{
		title:    'Khaby Lame',
		subtitle: 'Yara Shahidi Presents To',

		hash:      'lovies-2022-sa-khaby',
		videoID:   'khabylame',
		presenter: null,
		winner:    null,
		prefix:    'Social Media Person of the Year',

		needsWatch: true
	},
	{
		title:    'Edward Enninful',
		subtitle: 'Sofie Hagen Presents To',

		hash:      'lovies-2022-sa-edward',
		videoID:   'edwardenninful',
		presenter: null,
		winner:    null,
		prefix:    'Person of the Year',

		needsWatch: true
	},
	{
		title:     'Host of Lady Killers',
		subtitle:  'Lucy Worsley',
		hash:      '37edf835-48b3-4f80-b1c2-5a6567bff431',
		videoID:   'Ih7OXyZN8ZtqDeNRA5iD',
		presenter: null,
		winner:    null,
		prefix:    'People\'s Lovie | Best Individual Episode'
	},
	{
		title:    'Michelle Kennedy / Peanut',
		subtitle: 'Dr. Sue Black Presents To',

		hash:      'lovies-2022-sa-michelle',
		videoID:   'michellekennedy',
		presenter: null,
		winner:    null,
		prefix:    'Special Achievement',

		needsWatch: true
	},
	{
		title:    'Alice Oseman',
		subtitle: 'Claire Graves Presents To',

		hash:      'lovies-2022-sa-alice',
		videoID:   'aliceoseman',
		presenter: null,
		winner:    null,
		prefix:    'Special Achievement'
	},
	{
		title:    'Raphael Gomes',
		subtitle: 'Video Creator',

		hash:      '91e54dda-c7bd-410f-b89d-66db871bc5a5',
		videoID:   'gxMz6crUgIQMtMov4vPw',
		presenter: null,
		winner:    null,
		prefix:    'People\'s Lovie | Education and Discovery'
	},

	// message 16x9
	{
		title:    'The Lovie Awards',
		subtitle: 'A Message From',

		hash:      'lovies-2022-nic-and-nick',
		videoID:   'message',
		presenter: null,
		winner:    null,
		prefix:    ''
	},

	// 6 UP
	{
		title:    'Jaap Haartsen',
		subtitle: 'Sofie Hagen Presents To',

		hash:      'lovies-2022-sa-jaap',
		videoID:   'jaaphaartsen',
		presenter: null,
		winner:    null,
		prefix:    'Lifetime Achievement'
	},
	{
		title:    'Hosts of SexTok',
		subtitle: 'Zibby Owens & Tracey Cox',

		hash:      '69d4bd77-1351-4263-9bd0-842841d6045f',
		videoID:   'jbZEMHw3hXxECByxTUaH',
		presenter: null,
		winner:    null,
		prefix:    'Silver | Interview/Talk Show',

		needsWatch: true
	},
	{
		title:    'Pernille Harder',
		subtitle: 'Sofie Hagen Presents To',

		hash:      'lovies-2022-sa-pernille',
		videoID:   'pernilleharder',
		presenter: null,
		winner:    null,
		prefix:    'Special Achievement'
	},
	{
		title:    'Solar Sounds Festival',
		subtitle: 'Polar at the',

		hash:      '65efd641-35d0-469e-b7cb-36925baa9a8e',
		videoID:   'e9p6Kzbdgjh1YRTtDhWy',
		presenter: null,
		winner:    null,
		prefix:    'People\'s Lovie | Metaverse',

		needsWatch: true
	},
	{
		title:    'DEPT®',
		subtitle: 'Nic Roope Presents To',

		hash:      'lovies-2022-sa-dept',
		videoID:   'dept',
		presenter: null,
		winner:    null,
		prefix:    'Agency of the Year'
	},
	{
		title:    'ITN',
		subtitle: 'TikTok: Shortform News Explainers',

		hash:      '51064aeb-8e39-40b8-acc5-d3de93f73977',
		videoID:   'niTDdsFxhvpWMV79wqtS',
		presenter: null,
		winner:    null,
		prefix:    'People\'s Lovie | Social News Video',

		needsWatch: true
	},

	// closer 16x9
	{
		title:    'Sofie Hagen',
		subtitle: 'A Lovie Farewell From',

		hash:      'lovies-2022-closing',
		videoID:   'closing',
		presenter: null,
		winner:    null,
		prefix:    '',

		needsWatch: true
	}
]

// module.exports = flow
export default flow

export function flowIndex (id) {
	return flow.indexOf(findInFlow(id))
}

export function findInFlow (id) {
	return flow.find(v => v.videoID === id)
}

export function flowSlice (left, right) {
	return flow.slice(flowIndex(left) + 1, right && flowIndex(right))
}
