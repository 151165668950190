import React from 'react'
import Screens from './recorder/Screens'
import Header from './Header'
import Footer from './Footer'

import './App.scss'

function App ({ official }) {
	console.log(`OFFICIAL: ${official}`)
	return (
		<div className="App">
			<Header color="pink" />
			<Screens official={official} />
			<Footer />
		</div>
	)
}

export default App
